    @import url('https://fonts.googleapis.com/css2?family=Lugrasimo&display=swap');

    .animated-bg {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      min-height: 100vh;
      background: linear-gradient(-45deg, #5f5f6600, #848da600, #414d5d00, #a3a3e500);
      background-size: 400% 400%;
      animation: gradient 15s ease infinite;
      overflow: hidden;
    }

    .content {
      position: relative;
      z-index: 10;
      color: white;
      font-size: 24px;
      text-align: center;
      text-shadow: 0 2px 4px rgba(0,0,0,0.3);
      animation: float 6s ease-in-out infinite;
    }

    .particles {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    .particles::before,
    .particles::after {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      background-image: 
        radial-gradient(circle at 50% 50%, rgba(255,255,255,0.1) 0%, transparent 40%),
        radial-gradient(circle at 30% 70%, rgba(255,69,123,0.1) 0%, transparent 40%),
        radial-gradient(circle at 70% 30%, rgba(100,149,237,0.1) 0%, transparent 40%);
      animation: particleMove 20s linear infinite;
    }

    .particles::after {
      background-image: 
        radial-gradient(circle at 30% 30%, rgba(255,255,255,0.1) 0%, transparent 40%),
        radial-gradient(circle at 70% 70%, rgba(255,69,123,0.1) 0%, transparent 40%),
        radial-gradient(circle at 50% 50%, rgba(100,149,237,0.1) 0%, transparent 40%);
      animation: particleMove 30s linear infinite reverse;
    }

    @keyframes gradient {
      0% {
        background-position: 0% 50%;
      }
      50% {
        background-position: 100% 50%;
      }
      100% {
        background-position: 0% 50%;
      }
    }

    @keyframes particleMove {
      0% {
        transform: rotate(0deg) scale(1);
      }
      50% {
        transform: rotate(180deg) scale(1.5);
      }
      100% {
        transform: rotate(360deg) scale(1);
      }
    }

    @keyframes float {
      0%, 100% {
        transform: translateY(0);
      }
      50% {
        transform: translateY(-20px);
      }
    }

    /* Add shimmer effect */
    .animated-bg::before {
      content: "";
      position: absolute;
      top: -50%;
      left: -50%;
      width: 200%;
      height: 200%;
      background: linear-gradient(
        45deg,
        transparent 0%,
        rgba(255, 255, 255, 0.1) 50%,
        transparent 100%
      );
      animation: shimmer 10s linear infinite;
      pointer-events: none;
      z-index: 1;
    }

    @keyframes shimmer {
      0% {
        transform: translate(-50%, -50%) rotate(0deg);
      }
      100% {
        transform: translate(-50%, -50%) rotate(360deg);
      }
    }




    /* <!-- HTML !-->
    <button class="button-89" role="button">Button 89</button>
     */
    /* CSS */
    .button-89 {
      --b: 1px;   /* border thickness */
      --s: .15em; /* size of the corner */
      --color: #373B44;
      
      padding: calc(.2em + var(--s)) calc(.4em + var(--s));
      color: var(--color);
      --_p: var(--s);
      background:
        conic-gradient(from 90deg at var(--b) var(--b),#0000 90deg,var(--color) 0)
        var(--_p) var(--_p)/calc(100% - var(--b) - 2*var(--_p)) calc(100% - var(--b) - 2*var(--_p));
      transition: .3s linear, color 0s, background-color 0s;
      outline: var(--b) solid #0000;
      outline-offset: .6em;
      font-size: 16px;
    
      border: 0;
    
      user-select: none;
      -webkit-user-select: none;
      touch-action: manipulation;
    }
    
    .button-89:hover,
    .button-89:focus-visible{
      --_p: 0px;
      outline-color: var(--color);
      outline-offset: .05em;
    }
    
    .button-89:active {
      background: var(--color);
      color: #fff;
    }
